import { InjectionToken } from "@angular/core";
import { HeaderMenuItems } from "..";

export const DEFAULT_HEADER_MENU_ITEMS: HeaderMenuItems = {
    home: {
        label: "Home",
        route: "https://deloitte.ai",
        isExternal: true,
    },
    user: [
        {
            label: "My Subscriptions",
            route: "entitlements",
        },
        {
            label: "Order History",
            route: "purchases",
        },
        {
            label: "Asset Owner Hub",
            route: "https://aop.service.deloitte.ai/",
            isExternal: true,
        },
    ],
    center: [
        {
            label: "Storefront",
            route: "/catalog",
        },
    ],
};

export const HEADER_MENU_ITEMS_INJECTION_TOKEN = new InjectionToken<HeaderMenuItems>("Header Menu Items");
